import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/angular";
import { version } from '../package.json';
import {BlockNoteEditor} from "./app/editor/page-react/blocknote.react";
import reactToWebComponent from "react-to-webcomponent";
import React from 'react';
import ReactDOM from 'react-dom/client';

Sentry.init({
  dsn: "https://9e9292cc5e391d94f12576111313c72f@o4507539380895744.ingest.de.sentry.io/4507539384893520",
  release: "atwork-panel@" + version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/*.atwork\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Convert the React component into a Web Component
const BlockNoteWebComponent = reactToWebComponent(BlockNoteEditor, React, ReactDOM);

// Define the custom element (Web Component)
customElements.define('blocknote-editor', BlockNoteWebComponent);
